<template>
  <section class="container-fluid bg-white">
    <div class="row mb-5">
      <div class="d-none d-md-block col-md-6 pl-0">
        <div class="border w-100 h-auto position-relative">
          <map-svg
            :selectedProvinces="userProvince ? [userProvince] : []"
            ref="mapSVG"
            @onRegionClick="selectProvinceByMap"
          />
          <transition name="fade">
            <div
              class="
                overlay-container
                w-100
                position-absolute
                d-flex
                justify-content-center
                align-items-center
              "
              v-if="showProvinceGeneral"
            >
              <div
                class="overlay w-100 h-100 position-absolute"
                @click="overlayProvinceGeneralClick"
              ></div>
              <province-general
                class="w-75 province-general"
                :province="selectedProvinceGeneralOverlay"
                :nationalPlan="latestNationalPlan"
                :closeButton="true"
                @closeProvinceGeneral="hideProvinceGeneralOverlay"
              ></province-general>
            </div>
          </transition>
        </div>
      </div>
      <div class="col-12 col-md-6 mt-3">
        <h4 class="font-weight-bold text-left d-flex">เลือกจังหวัด</h4>
        <div class="p-3 peno-shadow rounded-normal mr-2" v-if="userProvince">
          <div class="row text-gray">
            <div class="col-5">จังหวัดที่คุณสังกัด</div>
            <div class="col-3">คะแนนปีที่แล้ว</div>
            <div class="col-4">ความคืบหน้าของแผน</div>
          </div>
          <div class="row mt-3 pointer" @click="selectProvince(userProvince)">
            <div class="col-5 font-weight-bold">
              {{ userProvince | province_name_locale }}
            </div>
            <div class="col-3">
              <score
                v-if="userProvince.previous_evaluation_score"
                class="text-center"
                :score="userProvince.previous_evaluation_score"
              ></score>
            </div>
            <report-progress-bar
              class="col-4"
              :progress="userProvince.progress"
            >
            </report-progress-bar>
          </div>
        </div>
        <div class="d-flex mt-4 justify-content-between align-items-center">
          <div class="text-h5 font-weight-bold">จังหวัดอื่นๆ</div>
          <div class="">
            <div class="input-group">
              <input
                class="form-control border-right-0 border"
                type="text"
                placeholder="ค้นหาจากชื่อจังหวัด"
                v-model="search"
              />
              <span class="input-group-append">
                <div class="input-group-text bg-transparent">
                  <eva-icon name="search-outline"></eva-icon>
                </div>
              </span>
            </div>
          </div>
        </div>
        <table class="table table-borderless mt-2">
          <thead class="header">
            <tr
              is="sorting-header"
              :headers="sortingHeaders"
              @onSorting="sorting"
            ></tr>
          </thead>
          <tbody class="body">
            <tr
              class="pointer"
              v-for="province in filteredProvinces"
              v-bind:key="province.id"
              @click="selectProvince(province)"
            >
              <td class="">{{ province | province_name_locale }}</td>
              <td>
                <score
                  v-if="province.previous_evaluation_score"
                  class="text-center"
                  :score="province.previous_evaluation_score"
                ></score>
              </td>
              <td>
                <report-progress-bar class="" :progress="province.progress">
                </report-progress-bar>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import MapSvg from '@/components/MapSvg.vue';
import SortingHeader from '@/components/SortingHeader.vue';
import ReportProgressBar from '@/components/ReportProgressBar.vue';
import ProvinceGeneral from '@/components/ProvinceGeneral.vue';
import Score from '@/components/Score.vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { head, find, orderBy, get } from 'lodash';

export default {
  name: 'PeonIndex',

  components: {
    SortingHeader,
    ReportProgressBar,
    ProvinceGeneral,
    Score,
    MapSvg,
  },

  data() {
    return {
      headers: [
        { title: 'จังหวัด', key: 'name_th' },
        { title: 'คะแนนปีที่แล้ว', key: 'previous_evaluation_score' },
        { title: 'ความคืบหน้าของแผน', key: 'progress' },
      ],
      search: '',
      sorted: {
        key: null,
        order: 'asc',
      },
      showProvinceGeneral: false,
      selectedProvinceGeneralOverlay: null,
      ready: false,
    };
  },

  created() {
    this.init();
  },
  computed: {
    ...mapState(['user', 'provinces']),
    ...mapGetters(['latestNationalPlan', 'getProvinceByISO']),
    userProvince() {
      return find(this.provinces, { id: (this.user.province || []).id });
    },
    sortingHeaders() {
      return [
        { title: 'จังหวัด', key: 'name_' + this.$i18n.locale },
        { title: 'คะแนนปีที่แล้ว', key: 'previous_evaluation_score' },
        { title: 'ความคืบหน้าของแผน', key: 'progress' },
      ];
    },
    filteredProvinces() {
      let filtered = this.provinces;
      if (this.search != null && this.search != '') {
        filtered = filtered.filter((province) => {
          return (
            province.name_th
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) !== -1
          );
        });
      }
      return this.sorted.key
        ? orderBy(filtered, [this.sorted.key], [this.sorted.order])
        : filtered;
    },
  },

  methods: {
    ...mapActions([
      'fetchProvincesStatus',
      'fetchPreviousPlanProvincesEvaluationScore',
    ]),
    async init() {
      const provincesStatus = await this.fetchProvincesStatus(
        this.latestNationalPlan
      );
      const provincesScore =
        await this.fetchPreviousPlanProvincesEvaluationScore(
          this.latestNationalPlan
        );
      this.ready = true;
    },
    sorting(sorted) {
      this.sorted = sorted;
    },
    selectProvince(province) {
      if (province.id == get(this.user.province, 'id')) {
        this.$router.push({ name: 'peno.overview.intro' });
      } else {
        this.$router.push({
          name: 'peno.other',
          params: { iso3166_2: province.iso3166_2 },
        });
      }
    },
    selectProvinceByMap(iso3166_2) {
      const province = this.getProvinceByISO(iso3166_2);
      this.showProvinceGeneralOverlay(province);
    },
    overlayProvinceGeneralClick() {
      this.hideProvinceGeneralOverlay();
    },
    showProvinceGeneralOverlay(province) {
      this.selectedProvinceGeneralOverlay = province;
      this.showProvinceGeneral = true;
    },
    hideProvinceGeneralOverlay() {
      this.selectedProvinceGeneralOverlay = null;
      this.showProvinceGeneral = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay-container {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.overlay {
  z-index: 4;
  background-color: #80808080;
}

.province-general {
  z-index: 5;
}
</style>
