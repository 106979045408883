<template>
  <div class="middle-dot text-h4" :class="[colorClass]">{{ score || '' }}</div>
</template>

<script>
export default {
  name: 'Score',

  props: {
    score: {
      type: Number,
      required: false,
    },
  },

  computed: {
    colorClass() {
      if (this.score >= 2.5) {
        return 'text-green';
      } else if (this.score >= 1.5) {
        return 'text-yellow';
      } else {
        return 'text-red';
      }
    },
  },
};
</script>

<style lang="scss"></style>
